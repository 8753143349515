<template>
  <section>
    <v-card>
      <calendar-validation />
      <calendar-vertical @display-update="onDisplayUpdateDialog" />
      <update-calendar-dialog :options.sync="updateDialog" />
    </v-card>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CalendarMobile',

  components: {
    CalendarVertical: () => import('@/components/GeneralCalendar/CalendarVertical'),
    UpdateCalendarDialog: () => import('@/components/GeneralCalendar/UpdateCalendarDialog'),
    CalendarValidation: () => import('@/components/GeneralCalendar/CalendarValidation'),
  },
  data: () => ({
    updateDialog: {
      display: false,
      milestoneUpdated: null,
    },
  }),

  computed: {
    ...mapGetters(['user']),
  },

  methods: {
    onDisplayUpdateDialog(milestoneUpdated) {
      this.updateDialog.milestoneUpdated = { ...milestoneUpdated }
      this.updateDialog.display = true
    },
  },
}
</script>

<style scoped>
.calendar__validation {
  padding-top: 10px;
  margin: 0px auto 0px auto;
  text-align: center;
}
</style>